import React from 'react';
import './Details.scss';
import HQAccordion from '../../Accordion/Accordion';
import vector from '../../../../images/arrow-vector.svg';
import PageContent from '../../PageContent';
import Heading from '../../Heading';
import Podcast from '../../Podcast/Podcast';

const Details = ({data}) => {

    const { content, colour, heading, menu_item} = data

    const {primary} = colour[0]

    return <div className="section details-page" id={menu_item[0].hash}>
        <div className="details-vector-container" style={{backgroundImage: `url(${vector})`}}></div>
        <div className="details-column">
            <div className="details-row">
                <div className="details-row-start details-row-header">
                    <div className="details-header-container">
                        <div className="details-header">
                            <Heading content={heading}></Heading>
                        </div>
                    </div>
                </div>
                <div className="details-row-center hq-hidden"></div>
                <div className="details-row-end hq-hidden"></div>
            </div>
            <div className="details-row">
                <div className="details-row-start">
                    <div className="details-content-container" style={{width: '448px'}}>
                        {content && content.map((item, index) => {
                            let copy;
                            if(item.text) {
                                copy = <PageContent bodyContent={item.text.copy} colour={primary}></PageContent>
                            }
                            if(item.accordion) {
                                copy = <HQAccordion heading={item.accordion.heading} items={item.accordion.accordion_list} colour={primary}></HQAccordion>
                            }
                            if(item.podcast) {
                                copy = <Podcast data={item.podcast.embed_code}></Podcast>
                            }
                            return <div key={index} className="details-content">
                                {copy}
                            </div>
                        })}
                    </div>
                </div>
                <div className="details-row-center"></div>
                <div className="details-row-end"></div>
            </div>
        </div>

    </div>
};

export default Details;

