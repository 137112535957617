import React, { Fragment, useEffect, useRef, useState} from 'react';
import HQLoader from '../Loader/Loader';
const Podcast = ({ data }) => {
    const embed_code = data;
    const podcastRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const container = podcastRef.current;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = embed_code;
        const div = tempDiv.getElementsByTagName('div')[0];
        const scriptSrc = tempDiv.getElementsByTagName('script')[0].src;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptSrc;
        script.onload = () => setLoading(false);
        container.appendChild(div);
        container.appendChild(script);
        tempDiv.remove();
    }, []);

    return  <Fragment>
        <div ref={podcastRef} id="podcast-container"></div>
        { loading && <HQLoader></HQLoader> }
    </Fragment>
};

export default Podcast;
