import React from 'react';
import HQButton from '../Button/Button';
import './LearnMore.scss';

const HQLearnMore = ({heading, copy, buttonText, buttonLink, primaryColour, secondaryColour, centerAlign = false}) => {

    return <div className={`learn-more-container${centerAlign ? ' centered' : ''}`}>
        <div className="learn-more-heading">{heading}</div>
        <div className="learn-more-copy" dangerouslySetInnerHTML={{__html: copy}}></div>
        <HQButton text={buttonText} link={buttonLink} primaryColour={primaryColour} secondaryColour={secondaryColour} ghost={false}></HQButton>
    </div>
};

export default HQLearnMore;

