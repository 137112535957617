import React from 'react';
import HQButton from '../../Button/Button';
import './InformationCTA.scss';
import vector from '../../../../images/arrow-vector.svg';
import HQLearnMore from '../../LearnMore/LearnMore';
import HQAccordion from '../../Accordion/Accordion';
import PageContent from '../../PageContent';
import Image from '../../Image';
import Heading from '../../Heading';
import CallOut from '../../CallOut';
import SocialSharing from '../../SocialSharing';
import Podcast from '../../Podcast/Podcast';

const InformationCTA = ({data, active}) => {

    const {image, content, colour, heading, cta_button, learn_more, menu_item, call_out, social_links} = data
    const imageContent = {
        imageUrl: image[0].hq_image.url,
        alt: image[0].alt
    }
    const {primary, secondary} = colour[0]

    return <div className="section information-cta" id={menu_item[0].hash}>
        <div className={'info-cta-image-container' + (menu_item[0].hash === active ? ' info-cta-parallax' : '')}>
            <Image imageContent={imageContent}></Image>
        </div>

        <div className="info-cta-vector-container">
            <img loading="lazy" src={vector} alt='vector'></img>
        </div>

        <div className="info-cta-column">
            <div className="info-cta-row">
                <div className="info-cta-row-start"></div>
                <div className="info-cta-row-center info-cta-row-header">
                    <div className="info-cta-header-container" style={{color:'white'}}>
                        <div className="info-cta-number-highlight" style={{background: primary}}></div>
                        <div className="info-cta-header">
                            <Heading content={heading}></Heading>
                        </div>
                    </div>
                </div>
                <div className="info-cta-row-end hq-hidden"></div>
            </div>
            <div className="info-cta-row">
                <div className="info-cta-row-start"></div>
                <div className="info-cta-row-center"></div>
                <div className="info-cta-row-end info-cta-content-spacer">
                    <div className="info-cta-content-container" style={{width: '448px', color:'white'}}>
                        {content && content.map((item, index) => {
                            let copy;
                            if(item.text) {
                                copy = <PageContent bodyContent={item.text.copy}></PageContent>
                            }
                            if(item.accordion) {
                                copy = <HQAccordion heading={item.accordion.heading} items={item.accordion.accordion_list} colour={colour[0].primary}></HQAccordion>
                            }
                            if(item.podcast) {
                                copy = <Podcast data={item.podcast.embed_code}></Podcast>
                            }
                            return <div key={index} className="info-cta-content">
                                {copy}
                            </div>
                        })}
                    </div>
                    <div className="info-cta-cta-container">
                        {cta_button && <HQButton callMeBack={cta_button[0].call_me_back} text={cta_button[0].text} link={cta_button[0].link.href} primaryColour={primary} secondaryColour={secondary} ghost={cta_button[0].ghost}></HQButton>}
                    </div>
                    <div className="info-cta-ext-links-mobile info-cta-column">
                        <SocialSharing shareLinks={social_links} hash={menu_item[0].hash}></SocialSharing>
                    </div>
                    <div className="info-cta-cta-section">
                        <CallOut callOutContent={{content: call_out, color: primary}}></CallOut>
                    </div>
                </div>
            </div>
            <div className="info-cta-row">
                <div className="info-cta-row-start"></div>
                <div className="info-cta-row-center"></div>
                <div className="info-cta-row-end">
                    <div className="info-cta-ext-links info-cta-column">
                        <div className="info-cta-learn-more-container" style={{marginBottom: '50px'}}>
                            {learn_more && <HQLearnMore heading={learn_more[0].heading} copy={learn_more[0].copy} buttonText={learn_more[0].button[0].text} buttonLink={learn_more[0].button[0].link.href} primaryColour={colour[0].primary} secondaryColour={colour[0].secondary}></HQLearnMore>}
                        </div>
                        <SocialSharing shareLinks={social_links} hash={menu_item[0].hash}></SocialSharing>
                    </div>
                </div>
            </div>
        </div>

    </div>
};

export default InformationCTA;

