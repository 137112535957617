import React from 'react';
import './styles.scss'

const Title = ({ titleContent }) => {

    if (!titleContent) {
        return '';
    }

    return (
        <div className="hq-title_container" dangerouslySetInnerHTML={{ __html: titleContent }} />
    );
};

export default Title