import React, { useRef, useState } from 'react';
import './Accordion.scss';

const HQAccordion = ({heading, items, colour}) => {

    const [activeIndex, setActiveIndex] = useState(null)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const content = Array.from({length: items.length}, ref => useRef(null))


    const handleClick = index => {
        if(activeIndex !== null) {
            content[activeIndex].current.setAttribute('style', 'height: 0px')
        }

        if (index === activeIndex) {
            return setActiveIndex(null);
        }

        setActiveIndex(index);
        content[index].current.setAttribute('style', `height: ${content[index].current.scrollHeight}px`)
    }

    return <div className={`accordion-container`}>
        <div className="accordion-heading-container"><p className="accordion-heading">{heading}</p></div>
        {items && items.map((item, index) => {
            return <div key={index} className="accordion-item">
                <div className="accordion-title" onClick={() => handleClick(index)}>
                    <p className="accordion-title-count" style={{color: colour}}>{index + 1}</p>
                    <p className="accordion-title-text">{item.title}</p>
                    <svg className={`accordion-title-arrow${index === activeIndex ? ' active' : ''}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path fill="#fff" fillRule="evenodd" d="M18 9.41L16.59 8 12 12.58 7.41 8 6 9.41l6 6 6-6z" clipRule="evenodd"/>
                    </svg>
                </div>
                <div className="accordion-content" ref={content[index]} dangerouslySetInnerHTML={{__html: item.copy}}></div>
            </div>
        })}
    </div>
};

export default HQAccordion;

