import React, { useState } from 'react';
import './Menu.scss'
import HQNavMenu from '../NavMenu/NavMenu';
import HQScrollIndicator from '../ScrollIndicator/ScrollIndicator';

const HQMenu = ({navItems, activePage = navItems[0].route, showIndicator = false, showLandingPage = true, prefixColour = '#b57f00', arrowColour = '#10738d'}) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const navArray = [...navItems];

    const getNavItems = () => {
        if (navArray && navArray.length && !showLandingPage) {
            navArray.shift();
        }
        return navArray;
    }

    return <div className={`menu-wrapper${menuOpen ? '' : ' menu-close'}`}>
        <HQNavMenu navItems={getNavItems()} activePage={activePage} prefixColour={prefixColour} arrowColour={arrowColour} handleOpen={setMenuOpen} open={menuOpen}></HQNavMenu>
        {showIndicator && <HQScrollIndicator navItems={navItems} activePage={activePage}></HQScrollIndicator>}
    </div>
};

export default HQMenu;

