import React from 'react';
import './Quote.scss';
import bubbles from '../../../images/bubbles.svg';

const HQQuote = ({text, colour, borderWidth = '16px', texture = bubbles, textureAttachment = 'fixed'}) => {

    return <div className="quote-container">
        <div className="quote-border" style={{backgroundColor: colour, minWidth: borderWidth, backgroundImage: texture ? `url(${texture})` : 'none', backgroundAttachment: textureAttachment}}></div>
        <p className="quote-text">{text}</p>
    </div>
};

export default HQQuote;
