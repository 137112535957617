import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import './Button.scss';

import {
    setSideBarOpen,
    setCmbDetailsObj,
} from '@om/redux/actions/publicWeb/callMeBack';

const HQButton = ({text, link, primaryColour = '#8F4100', secondaryColour = '#AC570F', ghost = false, callMeBack}) => {

    const [hover, setHover] = useState(false);
    const dispatch = useDispatch();

    const handleHover = () => {
        setHover(!hover);
    }

    const handleClick = () => {
        if (link) {
            window.open(link);
        }
        if (callMeBack) {
            const cmbDetails = callMeBack[0];
            if(cmbDetails) {
                dispatch(setCmbDetailsObj(cmbDetails));
            }
            dispatch(setSideBarOpen(true));
        }
    }

    return <Fragment>
        {!ghost &&
      <button className="button-container" onClick={handleClick} onMouseOver={handleHover} onMouseOut={handleHover} style={{background: !hover ? primaryColour : secondaryColour}}>
          <div className="button-text">{text}</div>
          <svg className="button-arrow" xmlns='http://www.w3.org/2000/svg' width='15' height='14' fill='none' viewBox='0 0 15 14'>
              <path fill='#FFFFFF' fillRule='evenodd' d="M8.4375 0.5L7.28086 1.64562L11.8582 6.1875H0V7.8125H11.8582L7.28086 12.3544L8.4375 13.5L15 7L8.4375 0.5Z" clipRule='evenodd'/>
          </svg>
      </button>
        }
        {ghost &&
      <button className="button-container button-ghost" onClick={handleClick} onMouseOver={handleHover} onMouseOut={handleHover} style={{borderColor: !hover ? '#a7a9ac' : primaryColour}}>
          <div className="button-text" style={{color: !hover ? '#a7a9ac' : primaryColour}}>{text}</div>
          <svg className="button-arrow" xmlns='http://www.w3.org/2000/svg' width='15' height='14' fill='none' viewBox='0 0 15 14'>
              <path fill={!hover ? '#a7a9ac' : primaryColour} fillRule='evenodd' d="M8.4375 0.5L7.28086 1.64562L11.8582 6.1875H0V7.8125H11.8582L7.28086 12.3544L8.4375 13.5L15 7L8.4375 0.5Z" clipRule='evenodd'/>
          </svg>
      </button>
        }

    </Fragment>
};

export default HQButton;

