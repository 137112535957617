import React from 'react';
import './ScrollIndicator.scss';

const HQScrollIndicator = ({navItems = [], activePage = navItems[0].route, defaultColour = '#a7a9ac'}) => {

    return <div className="nav-indicator-container">
        {navItems.map((item) =>
            <div key={item.route} className={`nav-indicator${item.route === activePage ? ' current-indicator' : ''}`} style={{backgroundColor: item.route === activePage ? item.primaryColour : defaultColour}}></div>
        )}
    </div>
};

export default HQScrollIndicator;

