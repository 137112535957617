import React,{useEffect, useRef} from 'react';
import './styles.scss'

const CallOut = ({ callOutContent }) => {
    const { content, color } = callOutContent;
    const anchorRef = useRef(null)

    useEffect(() => {
        anchorRef.current.firstElementChild.lastElementChild.style.color = color;
    }, [color])

    if (!callOutContent) {
        return '';
    }

    return (
        <div className="callout-container" >
            <span ref={anchorRef} className="callout-content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default CallOut