import React, { Fragment, useEffect, useRef } from 'react';
import './NavMenu.scss';

const HQNavMenu = ({navItems, activePage = navItems[0].route, open, handleOpen, prefixColour = '#b57f00', arrowColour = '#10738d'}) => {
    const ref = useRef(null)
    const questions = navItems.filter(item => item.type === 'Questions Page');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClose = () => {
        handleOpen(!open);
    }

    useEffect(() => {
        if (open) {
            handleClose()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {document.removeEventListener('click', handleClickOutside, true);}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickOutside = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            if (!ref.current.className.includes('ease-out')) {
                handleOpen(false);
            }
        }
    }

    const handleScroll = (id) => {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null,null,'#hq-'+id);
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
        handleClose();
    }

    const getQuestionCount = (route) => {
        if (questions ) {
            return questions.findIndex(question => question.route === route) + 1
        }
        return '*'
    }

    return <Fragment>
        <svg className="menu-icon" onClick={handleClose} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <rect width="18" height="2" x="3" y="6" fill="#A7A9AC" rx="1"/>
            <rect width="18" height="2" x="3" y="11" fill="#A7A9AC" rx="1"/>
            <rect width="18" height="2" x="3" y="16" fill="#A7A9AC" rx="1"/>
        </svg>
        <div ref={ref} className={`menu-container${!open ? ' ease-out' : ''}`}>
            <div className="menu-item row">
                <svg className="close-icon" onClick={handleClose} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <rect width="21.408" height="2" x="5.414" y="4" fill="#A7A9AC" rx="1" transform="rotate(45 5.414 4)"/>
                    <rect width="21.408" height="2" x="4" y="19.138" fill="#A7A9AC" rx="1" transform="rotate(-45 4 19.138)"/>
                </svg>
                <div className="menu-title"></div>
            </div>
            { navItems.map( (item, index) =>
                <div key={item.route} className={`menu-item row${ activePage === item.route ? ' selected' : '' }`} onClick={() => handleScroll(item.route)}>
                    <div className="menu-list-indicator" style={{color: prefixColour}}>{item.type === 'Questions Page' ? getQuestionCount(item.route) : '*'}</div>
                    <div className="menu-title row">
                        <p>{item.title}</p>
                        <svg className="arrow-icon" xmlns='http://www.w3.org/2000/svg' width='25' height='24' fill='none' viewBox='0 0 25 24'>
                            <path fill={ activePage === item.route ? '#808080' : arrowColour} fillRule='evenodd' d='M10.1 6L8.69 7.41 13.27 12l-4.58 4.59L10.1 18l6-6-6-6z' clipRule='evenodd'/>
                        </svg>
                    </div>
                </div>
            )}
        </div>
    </Fragment>
};

export default HQNavMenu;

