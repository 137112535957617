import React from 'react';
import PageContent from '../../PageContent';
import Title from '../../Title';
import Image from '../../Image';
import './styles.scss';

const LandingPage = ({ landingContent }) => {
    if (!landingContent) {
        return;
    }

    const { heading, image, content, menu_item } = landingContent;

    const imageContent = {
        imageUrl: image[0].hq_image.url,
        alt: image[0].alt,
    };

    return (
        <div className="section landing-root" id={menu_item[0].hash}>
            <div className="landing-image">
                <Image imageContent={imageContent} />
            </div>

            <div className="landing-heading">
                <Title titleContent={heading[0].header} />
            </div>

            <div className="landing-chevron landing-mobile">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 13.2782L21.18 10.7586L12 18.9429L2.82 10.7586L-1.14069e-07 13.2782L12 24L24 13.2782Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 2.51962L21.18 -1.38897e-07L12 8.1843L2.82 -1.04321e-06L-9.77417e-08 2.51962L12 13.2414L24 2.51962Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div className="landing-copy">
                <span className="landing-rect" />
                <PageContent bodyContent={content[0].text.copy} />
            </div>
            <div className="landing-chevron">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 13.2782L21.18 10.7586L12 18.9429L2.82 10.7586L-1.14069e-07 13.2782L12 24L24 13.2782Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24 2.51962L21.18 -1.38897e-07L12 8.1843L2.82 -1.04321e-06L-9.77417e-08 2.51962L12 13.2414L24 2.51962Z"
                        fill="white"
                    />
                </svg>
            </div>
        </div>
    );
};

export default LandingPage;
