import React, { useState } from 'react';
import HQQuote from '../../Quote/Quote';
import HQButton from '../../Button/Button';
import './ImageContentQuote.scss';
import texture from '../../../../images/vector.svg';
import HQLearnMore from '../../LearnMore/LearnMore';
import HQAccordion from '../../Accordion/Accordion';
import PageContent from '../../PageContent';
import Image from '../../Image';
import Heading from '../../Heading';
import SocialSharing from '../../SocialSharing';
import FindOutMore from '../../FindOutMore';
import Modal from '../../Modal';
import Podcast from '../../Podcast/Podcast';

const ImageContentQuote = ({data, showTexture = false, count, active, themeColor}) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalOptions, setModalOptions] = useState({content: '', podcast: false});

    const {quote, image, content, colour, heading, cta_button, learn_more, menu_item, social_links, find_out_more_items} = data
    const imageContent = {
        imageUrl: image[0].hq_image.url,
        alt: image[0].alt
    }
    const {primary, secondary} = colour[0];

    const handleModal = (isOpen, content, podcast = false) => {
        if (isOpen) {
            setModalOptions({content, podcast});
            document.body.style.overflow = 'hidden';
            setOpenModal(isOpen);
            return;
        }
        setOpenModal(isOpen);
        document.body.style.overflow = 'visible';
    }


    return <div className="section image-content-quote" id={menu_item[0].hash}>
        { !showTexture && <div className={'icq-image-container' + (menu_item[0].hash === active ? ' icq-parallax' : '')}>
            <Image imageContent={imageContent}></Image>
        </div>}
        {showTexture && <div className={'icq-image-container-texture' + (menu_item[0].hash === active ? ' icq-parallax-texture' : '')}>
            <Image imageContent={imageContent}></Image>
        </div>}
        {showTexture && <div className="icq-texture-container" style={{backgroundImage: `url(${texture}`}}>
        </div>}

        {openModal && <Modal isOpen={openModal} onClose={() => handleModal(false)} content={modalOptions.content} podcast={modalOptions.podcast} />}

        <div className="icq-column">
            <div className="icq-row">
                <div className="icq-row-start"></div>
                <div className="icq-row-center icq-row-header">
                    <div className="icq-header-container">
                        <div className={`icq-number-highlight${showTexture ? '-alt' : ''}`} style={{background: primary}}></div>
                        <div className="icq-header">
                            <p className="icq-question-count m-black" style={{color: primary}}>{count}</p>
                            <Heading content={heading}></Heading>
                        </div>
                    </div>
                </div>
                <div className="icq-row-end hq-hidden"></div>
            </div>
            <div className="icq-row">
                <div className="icq-row-start"></div>
                <div className="icq-row-center">
                    <div className="icq-content-container" style={{width: '448px'}}>
                        {content && content.map((item, index) => {
                            let copy;
                            if(item.text) {
                                copy = <PageContent bodyContent={item.text.copy}></PageContent>
                            }
                            if(item.accordion) {
                                copy = <HQAccordion heading={item.accordion.heading} items={item.accordion.accordion_list} colour={colour[0].primary}></HQAccordion>
                            }
                            if(item.podcast) {
                                copy = <Podcast data={item.podcast.embed_code}></Podcast>
                            }
                            return <div key={index} className="icq-content">
                                {copy}
                            </div>
                        })}
                    </div>
                </div>
                <div className="icq-row-end">
                    {quote && <div className="icq-quote-container" style={{width: '200px'}}>
                        <HQQuote text={quote[0].quote} colour={primary}></HQQuote>
                    </div>}
                </div>
            </div>
            <div className="icq-row hq-bubble-background">
                <div className="icq-row-start"></div>
                <div className="icq-row-center">
                    <div className="icq-additional-info icq-column">
                        <FindOutMore links={find_out_more_items} handleModal={handleModal} color={themeColor}></FindOutMore>
                        <div className="icq-cta-container">
                            {cta_button && <HQButton callMeBack={cta_button[0].call_me_back} text={cta_button[0].text} link={cta_button[0].link.href} primaryColour={primary} secondaryColour={secondary} ghost={cta_button[0].ghost}></HQButton>}
                        </div>
                    </div>
                </div>
                <div className="icq-row-end">
                    <div className="icq-ext-links icq-column">
                        <div className="icq-learn-more-container" style={{marginBottom: '50px'}}>
                            {learn_more && <HQLearnMore heading={learn_more[0].heading} copy={learn_more[0].copy} buttonText={learn_more[0].button[0].text} buttonLink={learn_more[0].button[0].link.href} primaryColour={colour[0].primary} secondaryColour={colour[0].secondary}></HQLearnMore>}
                        </div>
                        <div className="icq-cta-container-mobile">
                            {cta_button && <HQButton callMeBack={cta_button[0].call_me_back} text={cta_button[0].text} link={cta_button[0].link.href} primaryColour={primary} secondaryColour={secondary} ghost={cta_button[0].ghost}></HQButton>}
                        </div>
                        <SocialSharing shareLinks={social_links} hash={menu_item[0].hash}></SocialSharing>
                    </div>
                </div>
            </div>
        </div>

    </div>
};

export default ImageContentQuote;

