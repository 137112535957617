import React, { useEffect, useRef } from 'react';
import './styles.scss';

const PageContent = ({ bodyContent, colour }) => {
    const anchorRef = useRef(null);

    useEffect(() => {
        const lastElement = anchorRef.current.lastElementChild.firstElementChild
        if(lastElement && lastElement.hasAttribute('href')) {
            lastElement.style.color = colour;
        }
    }, [colour])

    return (
        <div ref={anchorRef} className="hq-content_container" dangerouslySetInnerHTML={{ __html: bodyContent }} />
    );
};


export default PageContent