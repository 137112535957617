import React from 'react';
import './styles.scss'

const Image = ({ imageContent }) => {

    const { imageUrl, alt = '' } = imageContent;

    return (
        <img className="hq-image" src={imageUrl} alt={alt}/>
    );
};

export default Image