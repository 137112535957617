import React from 'react';
import Podcast from '../Podcast/Podcast';
import './styles.scss'

const Modal = ({ isOpen, onClose, content, podcast = false }) => {

    if (!isOpen)
        return null

    return (
        <div id="myModal" className="modal">
            <div className="modal-content" style={{minWidth: podcast ? '60vw' : 'auto'}}>
                <div className="modal-close">
                    <span className="close" onClick={() => onClose()}>&times;</span>
                </div>
                {!podcast && <div className="modal-content-inner">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>}
                { podcast && <div className="modal-content-inner" style={{minHeight: '200px'}}>
                    <Podcast data={content}></Podcast>
                </div>}
            </div>
        </div>
    )
}

export default Modal
