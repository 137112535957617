import React from 'react';
import './styles.scss'

const FindOutMore = ({ links, handleModal, color = '#50b848' }) => {

    if(!links || !links.length) {
        return;
    }

    const icons = {
        download: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" stroke={color} d="M12.3498 2.25C13.2619 2.25 14.0013 2.9894 14.0013 3.90149L14.0012 8.4586L16.0471 8.45861C16.1631 8.45815 16.2714 8.51374 16.3389 8.60637L16.3599 8.6386C16.3922 8.69311 16.4096 8.7552 16.4102 8.81859C16.4096 8.88197 16.3922 8.94406 16.3599 8.99857L12.6242 15.4781C12.5604 15.5909 12.4409 15.6606 12.3114 15.6606C12.182 15.6606 12.0625 15.5909 11.9986 15.4781L8.26301 8.99857C8.19879 8.8872 8.19879 8.74998 8.26301 8.6386C8.32749 8.52678 8.44685 8.4581 8.57581 8.45861L10.6982 8.4586L10.6983 3.90149C10.6983 2.9894 11.4377 2.25 12.3498 2.25Z" strokeWidth="1.5"/>
            <path fillRule="evenodd" clipRule="evenodd" stroke={color} d="M20.5083 15.1641C21.1941 15.1641 21.75 15.72 21.75 16.4058V20.131C21.75 20.1729 21.7479 20.2144 21.7439 20.2553C21.7479 20.296 21.75 20.3374 21.75 20.3793C21.75 21.0651 21.1941 21.621 20.5083 21.621H4.36589C3.99139 21.621 3.65561 21.4552 3.42794 21.193C3.16582 20.9655 3 20.6297 3 20.2551L3 16.53C3 15.8442 3.55594 15.2882 4.24172 15.2882C4.92751 15.2882 5.48344 15.8442 5.48344 16.53L5.48334 19.1376L19.2665 19.1375L19.2666 16.4058C19.2666 15.72 19.8225 15.1641 20.5083 15.1641Z" strokeWidth="1.5"/>
        </svg>,
        article: <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke={color} d="M5.25 5.59375H16.0781M5.25 1.65625H16.0781C17.1654 1.65625 18.0469 2.53769 18.0469 3.625V19.375C18.0469 20.4623 17.1654 21.3438 16.0781 21.3438H5.25C4.16269 21.3438 3.28125 20.4623 3.28125 19.375V3.625C3.28125 2.53769 4.16269 1.65625 5.25 1.65625ZM5.25 17.4063H16.0781H5.25ZM5.25 14.4531H16.0781H5.25ZM5.25 11.5H16.0781H5.25ZM5.25 8.54688H16.0781H5.25Z" strokeWidth="1.5"/>
        </svg>,
        video: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" stroke={color} d="M20.4 3.75C21.5598 3.75 22.5 4.73497 22.5 5.95V18.05C22.5 19.265 21.5598 20.25 20.4 20.25H3.6C2.4402 20.25 1.5 19.265 1.5 18.05V5.95C1.5 4.73497 2.4402 3.75 3.6 3.75H20.4ZM9.99439 8.37C9.94079 8.37058 9.8883 8.38597 9.8422 8.41459C9.74764 8.47171 9.68957 8.57745 9.69 8.69169V15.3102C9.68957 15.4245 9.74764 15.5302 9.8422 15.5873C9.93638 15.6442 10.0524 15.6442 10.1466 15.5873L15.6257 12.2781C15.7211 12.2215 15.78 12.1157 15.78 12.001C15.78 11.8863 15.7211 11.7804 15.6257 11.7239L10.1466 8.41459C10.1005 8.38597 10.048 8.37058 9.99439 8.37Z" strokeWidth="1.5"/>
        </svg>,
        podcast: <svg width="30" height="30" style={{margin: '-3px 0px 0px -6px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path fill={color} d="M76.77,43.48a1.5,1.5,0,0,0-1.5,1.5v5.19H71.58V31.59A21.59,21.59,0,0,0,57.44,11.36a1.6,1.6,0,0,0-.49-.18,21.23,21.23,0,0,0-13.9,0,1.26,1.26,0,0,0-.48.18A21.59,21.59,0,0,0,28.42,31.59V50.17H24.73V45a1.5,1.5,0,0,0-3,0v6.68h0A28.06,28.06,0,0,0,48.5,79.91v8.58a1.5,1.5,0,1,0,3,0V79.92A28.07,28.07,0,0,0,78.27,52.16a4,4,0,0,0,0-.49V45A1.5,1.5,0,0,0,76.77,43.48Zm-45.35,3H43.31a1.5,1.5,0,0,0,0-3H31.42V39.79H43.31a1.5,1.5,0,0,0,0-3H31.42v-3.7H43.31a1.5,1.5,0,0,0,0-3H31.49A18.61,18.61,0,0,1,41.81,14.94v6.61a1.5,1.5,0,0,0,3,0V13.76a18.63,18.63,0,0,1,3.69-.67v8.46a1.5,1.5,0,0,0,3,0V13.09a18.87,18.87,0,0,1,3.7.67v7.79a1.5,1.5,0,0,0,1.5,1.5h0a1.5,1.5,0,0,0,1.5-1.5V14.94A18.58,18.58,0,0,1,68.5,30.09H56.69a1.5,1.5,0,1,0,0,3H68.58v3.7H56.69a1.5,1.5,0,0,0,0,3H68.58v3.69H56.69a1.5,1.5,0,0,0,0,3H68.58v3.69H31.42ZM68.5,53.17a18.56,18.56,0,0,1-37,0ZM50.22,77h-.68A25.07,25.07,0,0,1,24.76,53.17h3.73a21.56,21.56,0,0,0,43,0h3.74A25.08,25.08,0,0,1,50.22,77Z"/>
        </svg>,
    }

    const onClickContent = (link) => {
        switch (link.type.toLowerCase()) {
        case 'video':
        case 'article':
            handleModal(true, link.content)
            break;
        case 'podcast':
            handleModal(true, link.embed_code, true);
            break;
        case 'download':
            window.open(link.file.url, '_blank');
            break;
        default:
            window.open(link.content, '_blank');
            break;
        }
    }

    return (
        <div id="moreModal" className="find-out-more">
            <div className="find-out-more_heading">
                <p><span>FIND OUT MORE...</span></p>
            </div>
            <div className="find-out-more_inner">
                {links.slice(0,4).map((item, index) =>{
                    const icon = icons[item.type.toLowerCase()]
                    return (
                        <div className="find-out-more_content" key={index} style={{color}} onClick={() => onClickContent(item)}>
                            <p className="find-out-more_title"><span>{icon||icons.download }</span>{item.type}</p>
                            <p className="find-out-more_text">{item.description}</p>
                        </div>
                    )}
                )}
            </div>
        </div>
    )
}

export default FindOutMore
