import React, { useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import '../styles/HardQuestions/HqCampaignPage.css'
import HQMenu from '../components/hard-questions/Menu/Menu'
import ImageContentQuote from '../components/hard-questions/templates/ImageContentQuote/ImageContentQuote';
import ContentImageQuote from '../components/hard-questions/templates/ContentImageQuote/ContentImageQuote';
import QuoteContentImage from '../components/hard-questions/templates/QuoteContentImage/QuoteContentImage';
import LandingPage from '../components/hard-questions/templates/LandingPage';
import InformationCTA from '../components/hard-questions/templates/InformationCTA/InformationCTA';
import Footer from '../layouts/Footer';
import WebComponentsConfig from '../components/WebComponentsConfig';
import Details from '../components/hard-questions/templates/Details/Details';
import PageHeadTags from '../layouts/PageHeadTags';
import logo from '../images/logo-long.svg'
import CallMeBackSideDrawer from '../components/sideDrawers/CallMeBackSideDrawer';

const HQCampaignPage = ({ data, location }) => {

    const {segments, footer, meta_content, om_green}= data.contentstackHqCampaign;
    const [currentRoute, setCurrentRoute] = useState(null);
    let questionCount = 0;

    const routes = segments.map(segment => {
        return {
            route: segment.menu_item[0] ? segment.menu_item[0].hash : null,
            title: segment.menu_item[0] ? segment.menu_item[0].title : null,
            type: segment.page_type,
            primaryColour: segment.colour[0].primary,
            secondaryColour: segment.colour[0].secondary
        }
    })

    const smoothScroll = (id) => {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
    }

    const handleCurrentRoute = useCallback(
        () => {
            const sections = document.querySelectorAll('.section');
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                const scrollDownCheck = rect.top > 0 && rect.top < (window.innerHeight/2);
                const scrollUpCheck = rect.bottom > (window.innerHeight/2) && rect.top < 0;
                const scrollTopCheck = rect.top === 0 || rect.top === null;
                if ((scrollDownCheck || scrollUpCheck || scrollTopCheck) && (currentRoute !== section.id)) {
                    // eslint-disable-next-line no-restricted-globals
                    history.replaceState(null,null,'#hq-'+section.id);
                    setCurrentRoute(section.id);
                }
            })
        },
        [currentRoute],
    )

    useEffect(() => {
        if (location.hash) {
            smoothScroll(location.hash.replace('#hq-',''))
        } else {
            setCurrentRoute(routes ? routes[0].route : null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.addEventListener('scroll', handleCurrentRoute, true)
        return () => {document.removeEventListener('scroll', handleCurrentRoute, true)}
    }, [currentRoute, handleCurrentRoute])

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [])

    return <>
        { meta_content ? <PageHeadTags pageHeadTags={meta_content} /> : '' }
        <div className="hq-campaign-page">
            <div className="hq-campaign-page-wrapper">
                <img loading="lazy" src={logo} alt='logo' className='hq-logo'></img>
                <div className="hq-menu-container">
                    <HQMenu navItems={routes} activePage={currentRoute} showIndicator={true} showLandingPage={false}></HQMenu>
                </div>
                {
                    segments && segments.map((page,index) => {
                        let template;
                        switch(page.page_type) {
                        case 'Landing Page':
                            template = <LandingPage key={index}  landingContent={page}/>
                            break;
                        case 'Details Page':
                            template = <Details key={index} data={page} active={currentRoute}></Details>
                            break;
                        case 'Questions Page':
                            questionCount += 1;
                            if(page.template === 'ImageContentQuote') {
                                template = <ImageContentQuote key={index} data={page} count={questionCount} active={currentRoute} themeColor={om_green}></ImageContentQuote>}
                            if(page.template === 'ImageContentQuoteWithTexture') {
                                template = <ImageContentQuote key={index} data={page} count={questionCount} showTexture={true} active={currentRoute} themeColor={om_green}></ImageContentQuote>}
                            if(page.template === 'ContentImageQuote') {
                                template = <ContentImageQuote key={index} data={page} count={questionCount} active={currentRoute} themeColor={om_green}></ContentImageQuote>}
                            if(page.template === 'QuoteContentImage') {
                                template = <QuoteContentImage key={index} data={page} count={questionCount} active={currentRoute} themeColor={om_green}></QuoteContentImage>}
                            break;
                        case 'Information Page':
                            template = <InformationCTA key={index} data={page} active={currentRoute}></InformationCTA>
                            break;
                        default:
                            template = ''
                        }
                        return template
                    })
                }
                <WebComponentsConfig themeContent={[{theme_value: 'theme-wealth'}]}>
                    <Footer footerContent={footer[0]}></Footer>
                    <CallMeBackSideDrawer />
                </WebComponentsConfig>
            </div>
        </div>
    </>
};

export const pageQuery = graphql`
query HQCampaignPageQuery($id: String!) {
    contentstackHqCampaign(id: { eq: $id }) {
        meta_content {
            ...PageHeadTags
        }
        om_green
        segments {
            page_type
            template
            menu_item {
                hash
                title
            }
            colour {
                primary
                secondary
            }
            heading {
                header
            }
            image {
                hq_image {
                    url
                }
                alt
            }
            content {
                text {
                copy
                }
                accordion {
                    heading
                    accordion_list {
                        title
                        copy
                    }
                }
                podcast {
                    embed_code
                }
            }
            quote {
                quote
            }
            find_out_more_items {
                title
                type
                description
                content
                file{
                    url
                }
                embed_code
            }
            learn_more {
                heading
                copy
                button {
                    ghost
                    text
                    link {
                        href
                    }
                }
            }
            social_links {
                type
                link {
                    href
                    title
                }
            }
            cta_button {
                text
                ghost
                link {
                    href
                    title
                }
                call_me_back {
                    ...CallMeBack
                }
            }
            call_out
        }
        footer {
        ...Footer
        }
    }
}`;

export default HQCampaignPage;
