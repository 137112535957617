import React from 'react';
import './styles.scss'

const Heading = ({ content }) => {

    if (!content) {
        return '';
    }

    const { header } = content[0];

    return (
        <span className="hq-heading_container" dangerouslySetInnerHTML={{ __html: header }} />
    );
};

export default Heading